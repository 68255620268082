import { dev } from '$app/environment';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://e227c85c79974debb6bb51663d747384@o4505567485558784.ingest.sentry.io/4505582978727936',
	tracesSampleRate: 1.0,
	environment: dev ? 'development' : 'production',

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.05,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 0.8,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],
	ignoreErrors: [
		"Cannot read property 'domInteractive' of undefined",
		'braze is not defined',
		"Cannot read properties of undefined (reading 'domInteractive')"
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
